.footer {
  background: url("../images/footer-bg.png");
  position: relative;
  z-index: 1;
  padding-top: 24px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(#c9c8c8, 0.4);
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 30px;
  }

  &__map {
    height: 200px;
  }

  &__end {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 10px 0;
  }

  @media (max-width: 575px) {
    &__end {
      flex-direction: column;
    }

    &__copyright {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

.f-contact {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    position: relative;
    padding-left: 24px;

    a {
      color: inherit;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & > .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}

.f-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    padding-left: 12px;
    position: relative;
    left: 0;
    color: $gray-800;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      left: 5px;
    }

    &::before {
      content: "\f105";
      font-family: "fontawesome";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.f-social {
  display: flex;

  &__item {
    display: flex;
    width: 22px;
    height: 22px;
    border: 1px solid $black;
    justify-content: center;
    align-items: center;
    color: $black;

    &:hover {
      color: $black;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
