.preview {
  display: grid;
  grid-template-columns: 90px calc(100% - 106px);
  grid-template-rows: auto;
  grid-gap: 16px;
  grid-auto-flow: row dense;

  &__main {
    grid-column: 2 / span 1;
  }

  &__thumbs {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    position: relative;
  }

  @media (max-width: 575px) {
    grid-template-columns: 60px calc(100% - 70px);
    grid-gap: 10px;
  }
}

.preview-slider {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 100%);
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    color: $white;
    font-size: 30px;
    cursor: pointer;
    background: linear-gradient(to right, rgba(#000, 0.3), rgba(#000, 0));

    &:focus {
      outline: 0;
    }

    &::before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      border-top: 1px solid $white;
      border-left: 1px solid $white;
      transform: rotateZ(-45deg);
    }
  }

  &__next {
    background: linear-gradient(to left, rgba(#000, 0.3), rgba(#000, 0));
    left: auto;
    right: 0;

    &::before {
      transform: rotateZ(135deg);
    }
  }
}

.thumb-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.has-nav {
    padding: 20px 0;
  }

  &.has-nav &__prev,
  &.has-nav &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20px;
    background-color: #ddd;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:focus {
      outline: 0;
    }
  }

  &.has-nav &__next {
    top: auto;
    bottom: 0;
  }

  &:not(.has-nav) &__prev,
  &:not(.has-nav) &__next {
    display: none;
  }

  &__frame {
    @include frame("rect", 100%);
  }

  &__prev,
  &__next {
    display: none;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: auto !important;
  }
}
