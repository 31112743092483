.news-slider {
  position: relative;
  margin: -10px -15px;
  z-index: 1;

  &__nav {
    padding-right: 15px;
  }

  .swiper-slide {
    padding: 10px 15px;
  }
}

.product-slider {
  position: relative;
  margin: -10px;
  z-index: 1;

  &__nav {
    padding-right: 10px;
  }

  .swiper-slide {
    padding: 10px;
  }

  @media (max-width: 575px) {
    margin: -10px -8px;

    &__nav {
      padding-right: 8px;
    }

    .swiper-slide {
      padding: 10px 8px;
    }
  }
}

.product-slider-3 {
  position: relative;
  z-index: 1;
  margin: -10px -15px;

  &__nav {
    padding-right: 15px;
  }

  .swiper-slide {
    padding: 10px 15px;
  }
}

.partner-slider {
  position: relative;
  z-index: 1;
  margin: -10px 0;

  .swiper-slide {
    padding: 10px;
    height: auto;
  }

  &__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      object-fit: contain;
      object-position: 50% 50%;
      max-height: 120px;
    }
  }
}

.slider-nav {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  top: -50px;

  &__prev,
  &__next {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    color: #999;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    top: -45px;

    &__prev,
    &__next {
      width: 28px;
      height: 26px;
    }
  }

  @media (max-width: 575px) {
    &__nav {
      right: 8px;
    }
  }
}

.slider-nav-2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  &__prev,
  &__next {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    margin-right: 4px;

    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    margin-right: 15px;
  }
}
