.header {
  position: relative;
  z-index: 100;
  background: url("../images/footer-bg.png");

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(#c9c8c8, 0.4);
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__logo {
    display: inline-flex;
    margin: 12px auto 12px 0;

    img {
      height: 70px;
    }
  }

  &__hotline {
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;

    .fa {
      display: flex;
      width: 34px;
      height: 34px;
      justify-content: center;
      align-items: center;
      background-color: $green-light;
      color: $white;
      border-radius: 50%;
      font-size: 20px;
      margin-right: 12px;
    }

    a,
    a:hover {
      color: $green-light;
      text-decoration: none;
    }
  }

  &__btn {
    margin-left: 16px;
    border-color: $green-darker;
  }

  @media (min-width: 1200px) {
    &__logo {
      margin: 22px auto 22px 0;

      img {
        height: 116px;
      }
    }

    &__hotline {
      margin-left: 25px;
    }

    &__btn {
      display: none;
    }
  }
}

.lang {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid $border-color;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 20px;
    }
  }

  @media (min-width: 1200px) {
    padding: 0;
    border: 0;
    position: absolute;
    top: 22px;
    right: 0;
  }
}

.search {
  padding: 0 20px 10px;
  border-bottom: 1px solid $border-color;

  &__text {
    margin-top: 8px;

    a {
      color: inherit;
    }
  }

  .form-control {
    border-color: $primary;
  }

  .input-group-text {
    background-color: $green-light;
    color: $white;
    border-color: $primary;
  }

  @media (min-width: 1200px) {
    padding: 0 0 14px;
    border: 0;
    order: 1;

    .form-control {
      border-radius: 0;
      height: 50px;
      padding: 0 20px;
    }

    .input-group-text {
      border-radius: 0;
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
