.section {
  &__title {
    font-size: 25px;
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.section-2 {
  padding: 40px 0 60px;

  &:not(:first-child) {
    border-top: 1px solid #bbb;
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    padding-left: 25px;
    border-left: 10px solid $primary;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
      padding-left: 16px;
      border-left-width: 6px;
    }
  }
}

.section-3 {
  padding: 40px 0 60px;

  &:not(:first-child) {
    border-top: 1px solid #bbb;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    display: flex;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 0;
    line-height: 1.2;
    align-items: flex-start;
  }

  &__icon {
    display: inline-flex;
    width: 28px;
    height: 28px;
    position: relative;
    margin-right: 10px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 991px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
      border-left-width: 6px;
    }
  }
}

.aside {
  background-color: $white;
  @include shadow("");

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    background-color: $primary;
    padding: 7px 24px 6px;
  }
}
