.btn-default {
  border-color: $border-color;
  background-color: transparent;

  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.btn-movetop {
  display: none;
  position: fixed;
  z-index: $zindex-sticky;
  bottom: 30px;
  right: 30px;
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;
@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}
