$gutters: 4 8 10 16 20 30 40 50 60 80 100;

@each $gutter in $gutters {
  .gutter-#{$gutter} {
    margin: 0 -#{$gutter / 2}px;
    & > [class^="col"] {
      padding: 0 #{$gutter / 2}px;
    }
  }
}

@each $gutter in $gutters {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .gutter-over-#{$breakpoint}-#{$gutter} {
        margin: 0 -#{$gutter / 2}px;
        & > [class^="col"] {
          padding: 0 #{$gutter / 2}px;
        }
      }
    }

    @include media-breakpoint-down($breakpoint) {
      .gutter-under-#{$breakpoint}-#{$gutter} {
        margin: 0 -#{$gutter / 2}px;
        & > [class^="col"] {
          padding: 0 #{$gutter / 2}px;
        }
      }
    }
  }
}

// add column 20% (5 items in a row)
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

@each $key, $val in $grid-breakpoints {
  .col-#{$key}-20 {
    padding: 0 15px;
  }
  @media (min-width: #{$val}) {
    .col-#{$key}-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
