.contact-info {
  &__desc {
    a {
      color: inherit;
    }
  }
}

.contact-map {
  height: 400px;
}
