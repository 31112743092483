.product {
  background-color: $white;
  align-items: stretch;
  @include shadow("");

  &__frame {
    @include frame("rect", 100%);
    @include frame("zoom");
    width: 50%;
    order: 1;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 40px 10px;
    border: 1px solid $border-color;
    border-right: 0;
  }

  &__subtitle {
    color: #999;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 11px;
    margin-bottom: 6px;
  }

  &__desc {
    @include max-line(4);
    margin-bottom: 12px;
  }

  @media (max-width: 575px) {
    flex-direction: column;

    &__frame {
      width: 100%;
      order: 0;
    }

    &__body {
      border: 0;
      padding: 16px;
    }
  }
}

.product-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  @include shadow("");

  &__frame {
    @include frame("rect", 100%);
    @include frame("zoom");
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 16px 13px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
    flex-grow: 1;
  }

  @media (max-width: 575px) {
    &__title {
      padding: 10px 8px;
    }
  }
}

.product-3 {
  &__frame {
    @include frame("rect", 100%);
    width: 80px;
    margin-right: 12px;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: -2px;
  }

  &__desc {
    @include max-line(2);
    color: #999;
  }
}

.news {
  background-color: $white;
  @include shadow("");

  &__frame {
    @include frame("rect", 60%);
    @include frame("zoom");
  }

  &__body {
    flex-grow: 1;
    padding: 20px;
  }

  &__time {
    color: #999;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__desc {
    @include max-line(3);
    margin-top: auto;
    color: #999;
  }
}

.gallery {
  overflow: hidden;

  &__frame {
    @include frame("rect", 60%);
    @include frame("zoom");
  }

  &__list {
    display: none;
  }
}
