.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 16px;
    border-radius: 4px;
  }
}

.breadcrumb {
  padding: 15px 0;

  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }
}

$phoneRingColor: #e73a35;
.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $phoneRingColor;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: "";
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $phoneRingColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($phoneRingColor, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($phoneRingColor, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba($phoneRingColor, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: $phoneRingColor;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.button {
  @include btn("shine");
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 33px;
  padding: 0 25px;
  text-transform: uppercase;
  color: $white;
  background-color: #181818;
  border: 1px solid #181818;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &--lg {
    font-size: 16px;
    line-height: 38px;
  }
}

.sticky-btns {
  list-style: none;
  margin: 0;
  padding: 0 6px;
  background-color: $primary;
  position: fixed;
  z-index: 110;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  &__item {
    padding: 10px 4px;

    &:not(:last-child) {
      border-bottom: 1px solid $white;
    }
  }

  &__link {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    color: $white;
    border: 1px solid $white;
    transition: 0.3s;

    &:hover {
      background-color: $white;
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    right: 0;
  }
}

.follow {
  border-top: 1px solid $border-color;
  padding: 35px 0;

  &__inner {
    align-items: center;
  }

  &__text {
    flex: 0 0 50%;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  &__form {
    .form-control {
      height: 60px;
      border: 1px solid $primary;
      padding: 0 20px;
      border-radius: 0;
    }

    .input-group-text {
      border-radius: 0;
      color: $white;
      background-color: $primary;
      border-color: $primary;
      width: 90px;
      text-align: center;
      justify-content: center;
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    &__inner {
      flex-direction: column;
      align-self: stretch;

      & > .media-body {
        width: 100%;
      }
    }

    &__text {
      flex: unset;
      align-self: center;
      padding-right: 0;
      margin-bottom: 16px;
      max-width: 500px;
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;

    &__text {
      font-size: 14px;
    }

    &__form {
      .form-control {
        height: 45px;
      }

      .input-group-text {
        width: 60px;
        font-size: 20px;
      }
    }
  }
}

.awards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 60px;

  &__icon {
    width: 100%;
    max-width: 137px;
    height: 90px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 16px;

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
  }

  @media (max-width: 1199px) {
    grid-gap: 30px;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
}

.post {
  &-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &-about-title {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 10px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }
}

.product-section {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__desc {
    margin-bottom: 20px;
  }

  &__filter {
    padding: 15px;
    background-color: $white;
    margin-bottom: 24px;
    align-items: center;
  }
}

.as-menu {
  list-style: none;
  margin: 0;
  padding: 0 8px;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 0;
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__sub {
    list-style: none;
    margin: 0;
    padding: 0 0 8px;
  }

  &__sub-link {
    display: inline-block;
    color: #666;
  }
}

.product-info {
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__desc {
    padding: 16px 0;
    margin: 20px 0;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
  }

  &__widgets {
    align-items: center;
  }

  &__btn {
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
    padding: 0 50px;
    line-height: 50px;
    border: 0;
    background: $primary;
    color: $white;
    text-transform: uppercase;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }
  }

  &__hotline {
    margin-top: 35px;

    a {
      color: inherit;
      font-weight: 700;
    }
  }
}

.product-detail-tabs {
  .nav-item {
    &:not(:last-child) {
      margin-right: 80px;
    }
  }

  .nav-link {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 0;
    color: $gray-800;

    &:hover {
      color: $gray-800;
    }

    &.active {
      color: $primary;
    }
  }
}

.feature {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1px;
  border: 1px solid $border-color;
  background-color: $border-color;
  list-style: none;
  margin: 40px 0 20px;
  padding: 0;

  &__item {
    display: flex;
    background-color: $white;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  &__icon {
    width: 36px;
    height: 36px;
    position: relative;
    margin-right: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.pd-tabs {
  .nav-item {
    &:not(:last-child) {
      margin-right: 33px;
      position: relative;

      &::after {
        content: "";
        display: block;
        height: 16px;
        border-right: 1px solid $gray-800;
        position: absolute;
        top: 50%;
        right: -17px;
        transform: translateY(-50%);
      }
    }
  }

  .nav-link {
    padding: 0;
    color: $gray-800;

    &:hover,
    &.active {
      color: $primary;
    }
  }

  @media (max-width: 575px) {
    .nav-item {
      &:not(:last-child) {
        margin-right: 21px;

        &::after {
          right: -11px;
        }
      }
    }
  }
}

.banner-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
