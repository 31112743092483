.frame {
  &--circle {
    @include frame("circle");
  }

  &--1-1 {
    @include frame("rect", 100%);
  }

  &--16-9 {
    @include frame("rect", 56.25%);
  }

  &--3-2 {
    @include frame("rect", 66.66%);
  }

  &--4-3 {
    @include frame("rect", 75%);
  }

  &--2-1 {
    @include frame("rect", 50%);
  }

  &--3-4 {
    @include frame("rect", 133.33%);
  }

  &--3-2 {
    @include frame("rect", 150%);
  }

  &--contain {
    img {
      object-fit: contain;
    }
  }

  &--img-free {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--zoom {
    @include frame("zoom");
  }

  &--shine {
    @include frame("shine");
  }
}

.img-bg {
  @include img("bg");
}

.img-fit {
  @include img("fit");
}

.img-cover {
  @include img("cover");
}

.img-contain {
  @include img("contain");
}
