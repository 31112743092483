.list {
  list-style: none;
  margin: 0;
  padding: 12px 8px;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;
    }
  }
}
